import React from 'react';
import {useState, useContext} from 'react';
import styled from 'styled-components';
import {Input, Button} from 'antd';
import {StaticImage} from 'gatsby-plugin-image';
import {useOutlet, useOutletSetter} from 'reconnect.js';

import BannerImage from '../../images/FreeStayMock.png';
import EasyIcon from '../../images/easy.png';
import CustomerIcon from '../../images/customer.png';
import CloudIcon from '../../images/cloud.png';

import ThemeContext from '../../Theme';
import {navigate} from 'gatsby';

function Landing(props) {
  const theme = useContext(ThemeContext);
  const [storeId, setStoreId] = useState();
  const [user] = useOutlet('user');
  const showLoginModal = useOutletSetter('login-modal');

  const startUse = React.useCallback(() => {
    if (user) {
      navigate('/admin');
    } else {
      showLoginModal(true);
    }
  }, [user]);

  return (
    <Wrapper>
      <HeroBannerSection bgImg={BannerImage}>
        <div
          style={{
            padding: 20,
            backgroundColor: 'rgba(0,0,0,0.45)',
            borderRadius: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 120,
          }}>
          <h3 style={{color: '#2EFE9A'}}>將您的健康安全當成第一優先</h3>
          <Input.Search
            style={{
              width: 300,
              borderWidth: 2,
              borderColor: 'grey',
              marginTop: 20,
            }}
            placeholder="請輸入關心您的店家 8 碼編號"
            onSearch={() => {
              if ((storeId + '').length === 8) {
                navigate(`/store/?sid=${storeId}`);
              }
              setStoreId(null);
            }}
            enterButton
            allowClear
            bordered
            value={storeId}
            onChange={(e) => {
              setStoreId(e.target.value);
            }}
            type="number"
          />
        </div>
      </HeroBannerSection>

      <FlexItemSection style={{backgroundColor: '#ede0d4'}}>
        <h1 style={{color: '#1d3557'}}>疫情之下需要更安全的經營事業</h1>
        <div className="content">
          <QuickIntroItem
            title="省事"
            subTitle="全雲端免安裝"
            icon={CloudIcon}
            titleColor="#264653"
            subTitleColor="#2a9d8f"
          />

          <QuickIntroItem
            title="省力"
            subTitle="易於上手操作"
            icon={EasyIcon}
            titleColor="#7f5539"
            subTitleColor="#f4a261"
          />

          <QuickIntroItem
            title="省心"
            subTitle="顧客自行分流"
            icon={CustomerIcon}
            titleColor="#e76f51"
            subTitleColor="#e97c61"
          />
        </div>

        <div style={{display: 'flex', marginTop: 20}}>
          <p
            style={{
              marginRight: 10,
              padding: '10px 20px',
              borderRadius: 10,
              cusor: 'point',
              backgroundColor: 'orange',
              color: 'white',
              fontSize: 25,
              cursor: 'pointer',
            }}
            onClick={startUse}>
            立即開始
          </p>
          <p
            style={{
              padding: '10px 20px',
              borderRadius: 10,
              cusor: 'point',
              backgroundColor: '#219ebc',
              color: '#8ecae6',
              fontSize: 25,
              cursor: 'pointer',
            }}
            onClick={() => navigate('/store?sid=08491007')}>
            看看範例
          </p>
        </div>
        <a
          href="https://revteltech.pse.is/freestay-intro"
          target="_blank"
          style={{
            textDecoration: 'underline',
            marginTop: 20,
            color: 'black',
            fontSize: 18,
          }}
          rel="noreferrer">
          使用說明
        </a>
      </FlexItemSection>

      <RowBannerSection css="background-color:#D8D8D8;">
        <SloginWithTextBackground
          bgText="店家"
          title="服務升級原來這麼簡單！"
          content="簡單服務整合完善您最後一哩路的品質"
          actionText="後台開通店家 ⇨ 開始雲端報號"
        />
      </RowBannerSection>
      <RowBannerSection css="background-color:#E3E4E7;">
        <SloginWithTextBackground
          bgText="顧客"
          title="最短的等待才是最好的等待"
          content="讓您能將等待的時間發揮最大的效用"
          actionText="進入店家頁面 ⇨ 取回當前號碼"
          reverse
        />
      </RowBannerSection>
    </Wrapper>
  );
}

function QuickIntroItem({
  icon,
  title,
  subTitle,
  titleColor = 'black',
  subTitleColor = 'grey',
}) {
  return (
    <FlexItem>
      <img src={icon} style={{width: 50, height: 50}} />
      <div className="description">
        <h3 style={{color: titleColor}}>{title}</h3>
        <p style={{color: subTitleColor}}>{subTitle}</p>
      </div>
    </FlexItem>
  );
}

function SloginWithTextBackground({
  bgText,
  title,
  content,
  actionText,
  reverse = false,
}) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: reverse ? 'flex-end' : 'flex-start',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: 10,
      }}>
      <div
        style={{
          display: 'flex',
          width: '50%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <h2 style={{color: '#31B404'}}>{title}</h2>
        <p
          style={{
            overflowWrap: 'break-word',
            wordBreak: 'break-all',
            display: 'flex',
            marginTop: 5,
          }}>
          {content}
        </p>
        {actionText && (
          <h2 style={{marginTop: 25, color: '#4C76E5'}}>{actionText}</h2>
        )}
      </div>

      <div
        style={{
          position: 'absolute',
          ...(reverse ? {top: '15%', right: '15%'} : {top: '15%', left: '15%'}),
          zIndex: 5,
          color: 'rgba(0, 0, 0, 0.1)',
          fontSize: 80,
          fontWeight: 600,
        }}>
        {bgText}
      </div>
    </div>
  );
}

const Wrapper = styled.div`
  & > section {
    position: relative;
    overflow-x: hidden;
  }

  & .landing-slide-in-right {
    opacity: 0;
    transform: translateX(2000px);
    transition: 350ms;
  }

  & .landing-slide-in-right.delighter.started {
    opacity: 1;
    transform: translateX(0px);
  }

  & .landing-slide-in-left {
    opacity: 0;
    transform: translateX(-2000px);
    transition: 350ms;
  }

  & .landing-slide-in-left.delighter.started {
    opacity: 1;
    transform: translateX(0px);
  }

  width: 100%;
`;

const NavBar = styled.div`
  position: fixed;
  background-color: ${(props) => props.bgColor};
  top: 0px;
  left: 0px;
  width: 100vw;
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  box-shadow: ${(props) =>
    props.hasBorder
      ? '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)'
      : 'none'};
  transition: 200ms;
`;

const FlexItemSection = styled.section`
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .content {
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`;

const FlexItem = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > h3 {
      font-size: 24px;
      color: #888;
    }
    & > p {
      font-size: 18px;
      color: #ccc;
    }
  }
`;

const HeroBannerSection = styled.section`
  padding: 30px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  background: url('${(props) => props.bgImg}') no-repeat center;
  background-attachment: fixed;
  background-size: cover;

  & > h2 {
    font-size: 32px;
    color: black;
  }
  & > p {
    font-size: 18px;
    color: #ccc;
  }
`;

const DescriptionSection = styled.section`
  background-color: #cef6f5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
`;

const RowBannerSection = styled.section`
  padding: 40px 20px;

  & > .content {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > .description {
      display: flex;
      flex-direction: column;
      align-items: center;
      & > h2 {
        font-size: 32px;
        color: #888;
      }
      & > p {
        font-size: 18px;
        color: #ccc;
      }
    }
  }

  ${(props) => props.css || ''};
`;

const BigEmoji = styled.div`
  font-size: 100px;
`;

export default Landing;
